import React, { FunctionComponent } from "react"
import { Layout } from "../components/layout/Layout"
const IndexPage: FunctionComponent = () => {

  return (
    <Layout>
      <div>
        <h3>Tournament Overview</h3>
        <div>
          <a href="https://smash.gg/tournament/ssbu-misfire-2/details" target="_blank">Smash GG Overview</a>
          <br/>
          <a href="https://smash.gg/tournament/ssbu-misfire-2/attendees" target="_blank">Attendees</a>
          <br/>
          <a href="https://smash.gg/tournament/ssbu-misfire-2/event/ultimate-singles-bracket/brackets/880517/1408172" target="_blank">Swiss Pools (3 Rounds)</a>
          <br/>
          <a href="https://smash.gg/tournament/ssbu-misfire-2/event/ultimate-singles-bracket/brackets/880516/1408171" target="_blank">Double-Elimination Bracket</a>
          <br/>
        </div>
        <br/>
        <br/>

        <h3>Links</h3>
        <div>
          <a href="https://www.twitch.tv/macromackie" target="_blank">Tournament Stream</a>
          <br/>
        </div>
        <br/>
        <br/>

        <h3>FAQ</h3>
        <div>
          <ul>
            <li><strong>Is it best-of-3 or best-of-5?</strong></li>
            <ul>
              <li>Winners Finals, Losers Finals, and Grand Finals are best-of-5</li>
              <li>Everything else is best-of-3</li>
            </ul>
            <li><strong>What is the "Swiss Pools" format?</strong></li>
            <ul>
              <li>A round robin format which pairs up players with similar records. </li>
              <li>Players with 1-0 records will likely play each other in round 2 [likewise for those with 0-1 records].</li>
              <li>This will be used for seeding the elimination bracket, and should also give players some competitive practice before elimination begins.</li>
              <li>Note that, with 7 players, the top finisher in the swiss format will receive a bye - skipping the first round of elimination.</li>
            </ul>
            <li><strong>Why not round robin?</strong></li>
            <ul>
              <li>Round robin with every player would be a _lot_ of sets (6 for each player)</li>
              <li>With the swiss format, you can select a number of rounds (I've set it to 3 rounds)</li>
              <li>We could split into 2 pools and do a few sets each, but this should also result in more competitive sets.</li>
            </ul>
            <li><strong>What games will be streamed?</strong></li>
            <ul>
              <li>I'll try to stream some other matches, although likely most of the matches will just be whatever game I am in currently.</li>
              <li>We'll plan to play the top 4 games sequentially, and I'll just join as a spectator.</li>
              <li>Lets try to avoid having everyone spectate, as it seems to slow down the matches (watch the stream instead)</li>
            </ul>
          </ul>
          <br/>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
